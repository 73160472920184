import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "./ContentBlock";
import Image from "@/components/Image";
import ButtonServer, { ButtonIntent, ButtonStretch } from "./ui/button";
import Link from "next/link";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
interface Props {
  block?: Payload.BestSellersBlock;
}
export const BestSellersBlockServer = ({
  block
}: Props) => <ContentBlockServer className="flex flex-col mb-6 md:grid md:grid-cols-3" data-sentry-element="ContentBlockServer" data-sentry-component="BestSellersBlockServer" data-sentry-source-file="BestSellersBlock.tsx">
    {block?.product?.map(product => {
    return <div key={product.id} className="flex flex-col items-center justify-center p-4 md:justify-between">
          <div className="relative w-full mb-4 overflow-hidden bg-gray-100 aspect-square rounded-2xl">
            <Link href={`/product/${product.product?.slug}`} className="relative block h-full" prefetch={false}>
              <Image src={product.image?.url as string} alt={product.product?.name as string} width={640} height={640} fill sizes="960px" className="object-cover w-full h-full" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
            </Link>
          </div>
          <Link href={`/product/${product.product?.slug}`} className="text-lg font-semibold" prefetch={false}>
            {product.product?.name}
          </Link>
          <ButtonServer content="View Product" url={`/product/${product.product?.slug}`} className="my-4" intent={ButtonIntent.OutlinedCTA} stretch={ButtonStretch.flexible} />
        </div>;
  })}
  </ContentBlockServer>;
export default BestSellersBlockServer;